<template>
  <div class="_w-max _pc-pad-y-70 _mb-pad-y-60  _flex-col _pc-font-16 _mb-font-24">
    <!--    精选评价-->
    <div class="_w-max _pc-pad-x-260 _mb-pad-x-30 wow fadeInUp">
      <div class="_w-max">
        <!--      title标题-->
        <div class="featured-left-title _pc-font-16 _flex-row-end">
          <div class="_pc-font-42 _mb-font-52" style="color:var(--theme-color);font-weight: 800;line-height: 0.9;">
            精选评价</div>
          <div class="_pc-font-21 _mb-font-26 _pc-pad-left-20 _mb-pad-left-25 _grey-99" style="font-weight: 800;color: #999;">FEATURED</div>
        </div>

        <div class="_flex-row _pc-pad-y-50 _mb-pad-y-30 _mb-flex-col">
          <img class="_pc-w-660 _mb-w-max _mb-order-1" style="max-height: 30vw; object-fit: cover;" :src="featured[featured_index].images[0]"
            v-if="featured[featured_index].images.length > 0">
          <div class="_flex-1 _pc-pad-y-20 _mb-pad-y-30 _pc-pad-x-50 mb-pad-x-25 _flex-col-bet"
            style="background-color: #f7f7f7;position: relative;" v-if="featured[0]">
            <div class="_flex-col">
              <div class="_flex-row-mid _pc-mar-bottom-20 _mb-pad-bottom-5 _mb-display-block">
                <div class="_mb-flex-row-mid _mb-mar-bottom-20">
                  <img class="_pc-w-60 _mb-w-60 _border-circle" style="" :src="featured[featured_index].headimgurl"
                    alt="">
                  <span class="_pc-font-26 _mb-font-32 _mb-mar-left-20 _pc-display-none">{{
                    featured[featured_index].nickname }}</span>
                </div>
                <div class="_pc-mar-left-20 _mb-mar-left-0 _pc-font-16 _mb-font-24 _flex-col-center">
                  <span class="_pc-font-26 _pc-pad-bottom-10 _mb-pad-bottom-5 _mb-display-none">{{
                    featured[featured_index].nickname }}</span>
                  <span class="_w-max _mb-display-none">
                    <span>订单号: {{ featured[featured_index].ordersn }}</span>
                    <span class="_pc-pad-left-20 _mb-pad-left-60">路线: {{ featured[featured_index].area_express_name
                    }}</span>
                  </span>
                </div>
              </div>
              <div
                class="_pc-font-16 _mb-font-24 _flex-wrap _flex-row _pc-mar-y-10 _mb-mar-bottom-30 comm-bg _mb-pad-y-30">
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">客服服务:</div>
                  <div class="_mb-flex-row-mid">
                    <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                      :key="star_index" :src="star_index < featured[featured_index].service_score ? stars_t : stars_f"
                      :alt="item_star">
                  </div>
                </div>
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">物流速度:</div>
                  <div class="_mb-flex-row-mid">
                    <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                      :key="star_index" :src="star_index < featured[featured_index].express_score ? stars_t : stars_f"
                      :alt="item_star">
                  </div>
                </div>
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">包裹完整:</div>
                  <div class="_mb-flex-row-mid">
                    <img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                      :key="star_index" :src="star_index < featured[featured_index].pack_score ? stars_t : stars_f"
                      :alt="item_star">
                  </div>
                </div>
              </div>

              <span class="_w-max _pc-display-none _mb-mar-bottom-20">
                <span>订单号: {{ featured[featured_index].ordersn }}</span>
                <span class="_pc-pad-left-20 _mb-pad-left-60">路线: {{ featured[featured_index].area_express_name }}</span>
              </span>

              <div class="_line-4 _mb-mar-bottom-25" style="word-wrap: break-word ">
                {{ featured[featured_index].content }}
              </div>
            </div>
            <div class="_pc-mar-top-15 _flex-row-mid">
              <button
                class="_btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22"
                style="letter-spacing: 1.5px; cursor: pointer;"
                @click="featured_index > 0 ? featured_index-- : featured_index = featured.length - 1">
                <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-right-10 _mb-mar-right-10" style="transform: rotateY(180deg)"></i>
                上一个
              </button>
              <button
                class="_btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22"
                style="letter-spacing: 1.5px; cursor: pointer;"
                @click="featured_index < featured.length - 1 ? featured_index++ : featured_index = 0">
                下一个
                  <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10 _mb-mar-left-10"></i>
              </button>
            </div>
          </div>


          <!-- 占位 -->
          <div class="_flex-1 _pc-pad-y-20 _mb-pad-y-30 _pc-pad-x-50 mb-pad-x-25 _flex-col-bet"
            style="background-color: #f7f7f7;position: relative;" v-if="featured == ''">
            <div class="_flex-col">
              <div class="_flex-row-mid _pc-mar-bottom-20 _mb-pad-bottom-5 _mb-display-block">
                <div class="_mb-flex-row-mid _mb-mar-bottom-20"><img class="_pc-w-60 _mb-w-60 _border-circle" style=""
                    src="" alt=""><span class="_pc-font-26 _mb-font-32 _mb-mar-left-20 _pc-display-none"></span></div>
                <div class="_pc-mar-left-20 _mb-mar-left-0 _pc-font-16 _mb-font-24 _flex-col-center"><span
                    class="_pc-font-26 _pc-pad-bottom-10 _mb-pad-bottom-5 _mb-display-none"></span><span
                    class="_w-max _mb-display-none"><span>订单号: </span><span class="_pc-pad-left-20 _mb-pad-left-60">路线:
                    </span></span></div>
              </div>
              <div
                class="_pc-font-16 _mb-font-24 _flex-wrap _flex-row _pc-mar-y-10 _mb-mar-bottom-30 comm-bg _mb-pad-y-30">
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">客服服务:</div>
                  <div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10"
                      v-for="(item_star, star_index) in 5" :key="star_index" :src="stars_f" :alt="item_star"></div>
                </div>
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">物流速度:</div>
                  <div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10"
                      v-for="(item_star, star_index) in 5" :key="star_index" :src="stars_f" :alt="item_star"></div>
                </div>
                <div class="_pc-mar-bottom-10 _mb-pad-bottom-5 _w-050 _mb-w-033 _flex-row _mb-flex-col-mid">
                  <div class="_mb-w-max _text-center _mb-mar-bottom-20">包裹完整:</div>
                  <div class="_mb-flex-row-mid"><img class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10"
                      v-for="(item_star, star_index) in 5" :key="star_index" :src="stars_f" :alt="item_star"></div>
                </div>
              </div>
              <span class="_w-max _pc-display-none _mb-mar-bottom-20"><span>订单号: </span><span
                  class="_pc-pad-left-20 _mb-pad-left-60">路线: </span></span>
              <div class="_line-4 _mb-mar-bottom-25" style="word-wrap: break-word "></div>
            </div>
            <div class=" _flex-row-mid">
              <button
                class="btn _btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22"
                style="letter-spacing: 1.5px; cursor: pointer;"
                @click="featured_index > 0 ? featured_index-- : featured_index = featured.length - 1">
                <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-right-10 _mb-mar-right-10" style="transform: rotateY(180deg)"></i>上一个
              </button>
              <button
                class="btn _btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22"
                style="letter-spacing: 1.5px; cursor: pointer;"
                @click="featured_index < featured.length - 1 ? featured_index++ : featured_index = 0">
                下一个<i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10 _mb-mar-left-10"></i>
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!--   全部评价 -->
    <div class="_w-max wow fadeInLeft" style="background-color:#f7f7f7;">
      <div class="_w-max _pc-pad-x-260 _pc-mar-y-70 _mb-mar-y-60 _mb-pad-x-30">
        <!--      title标题-->
        <div class="msg-left-title _pc-mar-bottom-35 _mb-mar-bottom-30 _pc-font-16 _flex-row-end">
          <div class="_pc-font-42 _mb-font-52" style="color:var(--theme-color);font-weight: 800;line-height: 0.9;">
            全部评价</div>
          <div class="_pc-font-21 _mb-font-26 _pc-pad-left-20 _mb-pad-left-25" style="font-weight: 800;color: #999;">EVALUATION</div>
        </div>

        <!-- 全部评价列表-->
        <div class="_flex-row  _flex-wrap _mb-display-block">
          <div v-for="(item, index) in data.list" :key="index"
            class="_pc-w-430 _mb-w-max _pc-mar-y-40 _pc-pad-x-25 _pc-pad-bottom-20 _pc-mar-right-25 _mb-pad-x-30  _mb-pad-y-40 _mb-mar-bottom-30   _mb-mar-right-0 _pos-rel"
            style="background-color: #fff;">
            <!--用户信息-->
            <div class="_flex-row-end _mb-flex-row-mid _pos-rel _avar-d-5 _mb-mar-bottom-25" style="">
              <img style="background-color: #efefef;border-radius: 50%;" class="_pc-w-95 _avar-h _mb-w-45"
                :src="item.headimgurl" alt="">
              <div class="_pc-mar-left-25 _mb-mar-left-20 ">
                <div class="_flex-row-end _pc-pad-top-20 _mb-pad-top-5">
                  <div :title="item.nickname" class="_pc-pad-right-10 _pc-font-22 _mb-font-32 _line-1 _pc-w-100 _mb-w-max"
                    style="font-weight: 600;">{{ item.nickname }}</div>
                  <span class="_pc-w-170  _line-1 _mb-display-none" style="color: var(--theme-color);"
                    :title="item.area_express_name">{{ item.area_express_name }}</span>
                </div>
                <div class="_mb-display-none">{{ item.ordersn ? '订单号:' : '&nbsp;' }} {{ item.ordersn }}</div>
              </div>
            </div>

            <div class="_pc-display-none _mb-mar-bottom-15 _flex-row-mid _mb-font-24">
              <div class="_mb-w-050 _line-1">订单号:{{ item.ordersn }}</div>
              <div class="_mb-w-050 _line-1">路线:{{ item.area_express_name }}</div>
            </div>

            <!--评论星星-->
            <div class="_flex-col _mb-flex-row-mid _flex-wrap _mb-mar-bottom-30">
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050 _mb-mar-bottom-15">客户服务: <img
                  class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                  :key="star_index" :src="star_index < item.service_score ? stars_t : stars_f" :alt="item_star"></div>
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050 _mb-mar-bottom-15">物流速度: <img
                  class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                  :key="star_index" :src="star_index < item.express_score ? stars_t : stars_f" :alt="item_star"></div>
              <div class="_pc-mar-bottom-10 _mb-mar-bottom-5 _mb-w-050">物流速度: <img
                  class="_pc-mar-left-5 _pc-w-20 _mb-w-25 _mb-mar-right-10" v-for="(item_star, star_index) in 5"
                  :key="star_index" :src="star_index < item.pack_score ? stars_t : stars_f" :alt="item_star"></div>
            </div>
            <!--内容-->
            <div class="_min-h-80 comm-content _mb-mar-bottom-30" style="">
              {{ item.content }}
            </div>
            <div class="_flex-row-mid _pc-mar-top-25 _mb-mar-top-5">
              <img v-viewer class="image _pc-w-90 _mb-w-150  _pc-mar-x-20"
                style="background-color: #efeded;cursor: pointer;" v-for="(images_item, index) in item.images"
                :src="images_item" alt="1" v-show="index < 4" :key="index">
            </div>
          </div>
        </div>
        <!--分页-->
        <div class="_flex-row-center-mid _w-max _mb-display-none">
          <button @click="prePage"
            class="_btn-p-hover _pc-h-25 _border-radius-25 _pc-pad-x-30 _pc-mar-x-30 commbtn _pc-font-16">上一页</button>
          <div
            :class="page.currentPage == item ? point_page : '_pc-mar-x-15 _pc-w-25 _pc-h-25 _mb-h-5 _flex-row-mid _flex-row-center'"
            v-for="(item, index) in page.pageNum" :key="index" @click="getPageList(item)" style="cursor: pointer;">{{ item
            }}</div>
          <button @click="nextPage"
            class="_btn-p-hover   _pc-h-25 _border-radius-25 _pc-pad-x-30 _pc-mar-x-30 _pc-font-16 commbtn">下一页</button>
        </div>

        <div class=" _flex-row-center-mid _pc-display-none">
          <button @click="prePage"
            class="btn _btn-p-hover _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-50 _flex-row-center-mid _pc-font-16 _mb-font-22"
            style="letter-spacing: 1.5px; cursor: pointer;">
            <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-right-10 _mb-mar-right-10" style="transform: rotateY(180deg)"></i>上一个
          </button>
          <button @click="nextPage"
            class="btn _btn-p-hover  _mb-pad-x-40  _pc-pad-y-10 _mb-pad-y-20 _pc-mar-right-30 _mb-mar-right-30 _flex-row-center-mid _pc-font-16 _mb-font-22"
            style="letter-spacing: 1.5px; cursor: pointer;">
            下一个<i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10 _mb-mar-left-10"></i>
          </button>
        </div>
      </div>
    </div>
    <!--  集运动态  -->
    <div>
      <news-list></news-list>
    </div>

  </div>
</template>

<script>
import NewsList from "@/pages/avaluation/components/newsList";
export default {
  name: "evaluationIndex",
  components: { NewsList },
  data() {
    return {

      pc_avaluation_banner: '',
      featured_index: 0,
      stars_t: require('@/assets/image/stars1.png'),
      stars_f: require('@/assets/image/stars2.png'),
      data: {},
      featured: [],
      page: {
        pageNum: 1,
        pageSize: 6,//每页条数
        currentPage: 1 //页码
      },
      point_page: 'point_page _pc-mar-x-15 _pc-w-25 _pc-h-25 _mb-h-5'
    }
  },

  async asyncData() {
    return {
      stars_t: require('@/assets/image/stars1.png'),
      stars_f: require('@/assets/image/stars2.png'),
      data: {
        // 全部评论
        list: [
          {

          },
        ],

        count: '',
        //精选评论
        featured: [
          {

          }
        ]
      },
      featured: [
        {}
      ],
    }
  },
  metaInfo: {
    title: '嘟嘟集运 - 跨国集运专家 | 海外华人留学生集运服务',
    meta: [{
      name: 'Keywords',
      content: '靠谱集运,靠谱马来西亚集运,淘宝集运,拼多多集运,抖音集运,小红书集运,京东集运,temu集运,tiktok集运,海南嘟嘟,嘟嘟快递,嘟嘟货代,嘟嘟集运,嘟嘟国际快递集运转运,嘟嘟国际集运转运,东南亚物流,东南亚集运,马来西亚空运,马来西亚集运推荐,马来西亚华人,马来西亚海运,新加坡物流,新加坡空运,新加坡集运推荐,新加坡华人,新加坡海运,中国寄马来西亚,中国寄新加坡,中国寄泰国,泰国空运,泰国集运推荐,泰国华人,泰国海运,马来西亚tng支付,马来西亚代付,集运仓、淘宝集运、集运国际快递'
    },
    {
      name: 'Description',
      content: '嘟嘟集运，您的跨国集运专家。专为海外华人和留学生提供便捷、高效的集运服务。无论您身在何处，享受家乡商品的乐趣。轻松、快捷、安全，让您的海外生活更加丰富多彩。加入我们，探索更多集运优惠和服务。'
    }]
  },
  mounted() {
    this.getList()
    setTimeout(() => {
      new this.$wow.WOW().init()
    }, 20)
  },
  methods: {
    //获取评论信息
    async getList() {
      let data = {
        page: this.page.currentPage,
        limit: this.page.pageSize
      }
      await this.$axios.get(
        'site/get_site_comment',
        {
          params: data
        }
      ).then(res => {
        this.data = res.data.data
        this.pc_avaluation_banner = this.data.avaluation_banner
        this.pageNum1();
        let featured = [];
        for (let i = 0; i < res.data.data.featured.length; i++) {
          if (res.data.data.featured[i].is_hot == 1) {
            featured.push(res.data.data.featured[i])
          }
        }

        this.featured = featured;
      })
    },
    //  页数
    pageNum1() {
      let page = Math.ceil(this.data.count / this.page.pageSize) || 1;
      this.page.pageNum = page;
    },
    getPageList(index) {
      this.page.currentPage = index;
      this.getList()
    },
    nextPage() {
      if (this.page.currentPage === this.page.pageNum) {
        return ''
      }
      this.page.currentPage = ++this.page.currentPage;
      this.getList()
    },
    prePage() {
      if (this.page.currentPage === 1) {
        return ''
      }
      this.page.currentPage = --this.page.currentPage;
      this.getList()
    }
  }
}
</script>

<style scoped>
* {
  color: #333333;
}

button{
  background: transparent;
}

.msg-left-title,
.featured-left-title {
  position: relative;
}

.btn {
  border: 1px solid var(--theme-color-bg);
  color: var(--theme-color);
  background-color: #fff;
}

/* .btn:hover {
   content: '';
   background-color: rgba(226,31,25,0.1);
  cursor: pointer;
} */
.point_page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: var(--theme-color-bg);
  color: var(--theme-color-title);
  border-radius: 50%;
}

.commbtn {
  border: 1px solid var(--theme-color-bg);
  color: var(--theme-color);
  cursor: pointer;
}

._text-line-number_1 {
  line-height: 1.6;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}


._btn-p-hover {
  border: 1px solid var(--linear-btn-bg);
  color: var(--linear-btn-text);
}

._btn-p-hover:hover {
  background: var(--theme-color-bg);
  border-color: var(--theme-color-bg);
  color: var(--theme-color-title);
}

._btn-p-hover:hover .bzjygw-a-ziyuan81{
  color: var(--theme-color-title);
}

._btn-p-hover .bzjygw-a-ziyuan81{
  font-size: 0.36vw;
  color: var(--linear-btn-text);
}

.right-ico-hover {
  display: block;
}

.right-ico-02 {
  display: none;
}

._btn-p-hover:hover .right-ico-02 {
  display: block;
}

._btn-p-hover:hover .right-ico-hover {
  display: none;
}

._min-h-80 {
  height: 3.6vh;
}

.comm-content {
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  line-height: 1.6
}

._avar-d-5 {
  top: -5%;
}

._avar-h {
  height: 4.6875vw;
}

.image{
  height: 4.6875vw;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .image{
    height: 20vw;
  }

  .point_page {
    height: 0.88052vh;
    width: 0.88052vh;
  }

  ._min-h-80 {
    height: unset;
  }

  .msg-left-title:after {
    content: '';
  }

  .featured-left-title:after {
    content: '';
  }

  .comm-bg {
    background: #d0d0d050;
    border-radius: 1.33333vw;
  }

  ._avar-d-5 {
    top: unset;
  }

  ._avar-h {
    height: 6vw;
  }

  ._btn-p-hover {
    background: var(--theme-color-bg);
    border-color: var(--theme-color-bg);
    color: var(--theme-color-title);
  }

  ._btn-p-hover .bzjygw-a-ziyuan81{
    font-size: 1.07vw;
    color: var(--theme-color-title);
  }
}
</style>

<template>
  <div class="_w-max _pc-mar-y-45 _mb-mar-top-60 _pc-pad-x-260 _mb-pad-x-30 _flex-col wow fadeInRight" v-if="data.length">
    <!--      title标题-->
    <div class="news_left-title _pc-mar-bottom-35 _mb-mar-bottom-30 _pc-font-16 _mb-font-36 _flex-row-end">
      <div class="_pc-font-42 _mb-font-58" style="color:var(--theme-color);font-weight: 800;line-height: 0.9;">NEWS</div><div class="_pc-font-28 _pc-pad-left-20 _mb-font-36 _mb-pad-left-25" style="font-weight: 800;color: #999;">集运动态</div>
    </div>

    <div class="grid-newList">
      <router-link :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(3)}}" class="_flex-col _pc-w-max _mb-flex-row _mb-mar-bottom-35 grey-bg "  v-for="(item,index) in data" :key="index" v-show="index<3">
        <img class="_pc-w-max _pc-h-200 _mb-w-260 _mb-mar-right-25" :src="item.image_input" alt="">
        
        <div class="_pc-w-max _flex-row-bet _pc-pad-x-20 _pc-pad-y-20 _mb-pad-y-0 _mb-w-max _mb-flex-col">
          <div class="_flex-col _mb-flex-col-bet _mb-h-max">
            <span class="_pc-mar-bottom-10 ">{{ item.title }}</span>
            <span class="_pc-mar-bottom-10 _pc-display-none _grey-99">{{ item.synopsis }}</span>
            <span class="_flex-row-mid _mb-grey-99">
              <img class="_mb-w-20 _mb-mar-right-10 _pc-display-none" src="@/assets/image/icon-clock.png" alt="">
              <div>{{ item.add_time }}</div>
            </span>
          </div>
            <router-link class="_mb-display-none detail-btn _flex-row-center-mid" :to="{path:'/newList/detaile',query:{index:JSON.stringify(index),page:JSON.stringify(page),keyword:JSON.stringify(3)}}">
              <!-- <img @mouseover="mouseover(index)" @mouseleave="mouseleave" class="_pc-w-45" :src="clickIndex==index?newsover:newsaleave"  alt="enter"> -->
               <i class="jy-iconfont bzjygw-a-ziyuan7"></i>
            </router-link >
        </div>

       </router-link >
    </div>

    <div class="_w-max _flex-row-center-mid _pc-mar-top-45 _mb-mar-top-25 _mb-display-none">
      <router-link to="/newList" style="align-self:flex-start;">
        <div class="_btn-p-hover _pc-pad-x-30 _pc-pad-y-15 _mb-pad-y-5 _flex-row-mid title-color _pc-font-16" style="align-self:flex-start;">
          查看更多 
          <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
        </div>
      </router-link>
    </div>

    <div class="_flex-row-center-mid _pc-display-none _mb-mar-top-30  _white">
      <router-link to="/newList"
        class=" _mb-font-22 _mb-w-180 _mb-pad-y-15 _text-center _btn-p-hover _flex-row-center-mid"
        style="cursor: pointer;"
      >
      查看更多
      <i class="jy-iconfont bzjygw-a-ziyuan81 _pc-mar-left-10"></i>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
name: "newsList",
  data(){
    return {
      data:[

      ],
      newsover: require('@/assets/image/news-right02.png'),
      newsaleave: require('@/assets/image/news-right.png'),
      clickIndex:-1,
      page:{
        pageNum:1,
        pageSize:9,//每页条数
        currentPage:1 //页码
      },
    }
  },
  async asyncData(){
    return {
      data:[
        {
          title:'下单常见问题，你想知道的都在这儿',
          add_time:'2022-08-13',
        },
      ]
    }
  },
  created() {
  },
  mounted() {
  this.getList()
  },
  methods:{
    //获取评论信息
    async getList(){
      let that = this;
      let data = {
        cid:3
      }
      await this.$axios.get(
        'site/get_site_newList',
        {
          params:data
        }
      ).then(res => {
        res.data.data.all.forEach(val=>{
          if(that.data.length<=2){
            that.data.push(val)
          }
        })
      })
    },
    mouseover(index){
      this.clickIndex = index
    },
    mouseleave(){
      this.clickIndex = -1
    }
  }
}
</script>

<style scoped>
.btn:hover {
  background-color: var(--theme-color-bg);
}
.news_left-title {
  position: relative;
}

._btn-p-hover {
  border: 1px solid var(--linear-btn-bg);
  color: var(--linear-btn-text);
}

._btn-p-hover:hover {
  background: var(--theme-color-bg);
  border-color: var(--theme-color-bg);
  color: var(--theme-color-title);
}

._btn-p-hover:hover .bzjygw-a-ziyuan81{
  color: var(--theme-color-title);
}

._btn-p-hover .bzjygw-a-ziyuan81{
  font-size: 0.36vw;
  color: var(--linear-btn-text);
}
.right-ico-hover{display: block;}
.right-ico-02{display: none;}
._btn-p-hover:hover .right-ico-02{display: block;}
._btn-p-hover:hover .right-ico-hover{display: none;}
.grey-bg{background-color: #f7f7f7;}
.grid-newList{
  display: grid;
  grid-template-columns: 22.65625vw 22.65625vw 22.65625vw;
  gap: 2.34375vw;
}
.grey-bg:hover {
  background-color: var(--theme-color-bg);
}
.grey-bg:hover .detail-btn {
  background-color: var(--linear-btn-bg);
  color: var(--theme-color);
}
.detail-btn{
  width: 2.29vw;
  height: 2.29vw;
  border-radius: 50%;
  text-align: center;
  background: var(--theme-color-bg);
  color: var(--theme-color-title);
}
.detail-btn .jy-iconfont {
  font-size: 0.47vw;
}
@media screen and (max-width: 768px) {
 .news_left-title:after{content: '';}
 .grid-newList{display:block;}
 .grey-bg{background-color: #fff;}
 ._btn-p-hover {
    background: var(--theme-color-bg);
    border-color: var(--theme-color-bg);
    color: var(--theme-color-title);
  }

  ._btn-p-hover:hover {
    background: var(--theme-color-bg);
    border-color: var(--theme-color-bg);
    color: var(--theme-color-title);
  }

  ._btn-p-hover .bzjygw-a-ziyuan81{
    font-size: 1.07vw;
    color: var(--theme-color-title);
  }
}

</style>
